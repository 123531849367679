<template>
  <div
    class="page"
    :class="{ 'page--sm-and-up': smAndUp }"
  >
    <section class="section bg-light text-secondary">
      <v-img
        :src="BgLanding"
        position="top center"
        class="align-end section__background"
      >
        <div class="pa-5 linear-bg">
          <h1 class="mb-10 text-center font-weight-black">
            <div class="line-1">Obtenez</div>
            <div class="line-2 text-primary">-10%</div>
            <div class="line-3">
              sur plus de 1 200 produits de tous les jours
            </div>
          </h1>

          <h3 class="text-h4 font-weight-bold">Salsa</h3>
          <p class="mb-2 font-weight-bold">
            Le programme fidélité des grandes marques
          </p>
          <p class="font-weight-medium">
            Connectez votre compte Leclerc ou Carrefour et bénéficiez de remises
            exclusives toute l’année.
          </p>
          <div class="section__bottom__link">
            <AppLinkDownloadApp />
          </div>
        </div>
      </v-img>
    </section>

    <section class="section bg-dark text-white">
      <div class="section__top">
        <v-img
          :src="HowToCards"
          class="section__top__img"
        />
      </div>
      <div class="section__bottom">
        <h3 class="section__bottom__title">
          Connectez votre carte de fidélité
        </h3>
        <p class="section__bottom__p">
          Salsa se base sur votre historique d’achat pour appliquer les remises
          des marques partenaires
        </p>
        <div class="section__bottom__link">
          <AppLinkDownloadApp />
        </div>
      </div>
    </section>

    <section class="section bg-light text-secondary">
      <div class="section__top">
        <v-img
          :src="HowToWhere"
          class="section__top__img"
        />
      </div>
      <div class="section__bottom">
        <h3 class="section__bottom__title">Partout, même au drive</h3>
        <p class="section__bottom__p">
          Salsa fonctionne partout où votre carte de fidélité fonctionne : en
          magasin, mais aussi en drive et drive piéton
        </p>
        <div class="section__bottom__link">
          <AppLinkDownloadApp />
        </div>
      </div>
    </section>

    <section class="section bg-dark text-white">
      <div class="section__top">
        <v-img
          :src="HowToHandOverCard"
          class="section__top__img"
        />
      </div>
      <div class="section__bottom">
        <h3 class="section__bottom__title">
          Cagnottez à chaque passage en caisse
        </h3>
        <p class="section__bottom__p">
          Présentez votre carte de fidélité habituelle à chaque passage
        </p>
        <div class="section__bottom__link">
          <AppLinkDownloadApp />
        </div>
      </div>
    </section>

    <section class="section bg-light text-secondary">
      <div class="section__top">
        <v-img
          :src="HowToCashOut"
          class="section__top__img"
        />
      </div>
      <div class="section__bottom">
        <h3 class="section__bottom__title">
          Retirez votre cagnotte Salsa à tout moment
        </h3>
        <p class="section__bottom__p">Par virement sur votre compte bancaire</p>
        <div class="section__bottom__link">
          <AppLinkDownloadApp />
        </div>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import BgLanding from '~/assets/images/landing-pages/bg-landing.png'
import HowToCards from '~/assets/images/landing-pages/how-to.cards.png'
import HowToWhere from '~/assets/images/landing-pages/how-to.where.png'
import HowToHandOverCard from '~/assets/images/landing-pages/how-to.hand-over-card.png'
import HowToCashOut from '~/assets/images/landing-pages/how-to.cash-out.png'

const { smAndUp } = useDisplay()

// const localPath = useLocalePath()
</script>

<style lang="scss" scoped>
$color-light: #f2f2f7;

.bg-light {
  background-color: $color-light;
}
.bg-dark {
  background-color: #333a73;
}

.linear-bg {
  padding-top: 50px !important;
  background: linear-gradient(
    to bottom,
    transparent 0,
    $color-light 100px
  ) !important;
}

.page {
  height: 100vh;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
}
.section {
  height: 100vh;
  scroll-snap-stop: always;
  scroll-snap-align: center;
}

.page--sm-and-up {
  .section__background {
    max-width: 500px;
    margin: auto;
  }
  .section__top__img {
    max-width: 500px;
    margin: auto;
  }
  .section__bottom {
    flex-grow: 1;
    max-width: 500px;
    margin: auto;
  }
}

.section {
  display: flex;
  flex-direction: column;
}
.section__top {
  flex-grow: 1;
}
.section__bottom {
  flex-grow: 0;
}

.section__top,
.section__bottom {
  margin: 20px;
}
.section__bottom__title {
  margin-bottom: 12px;

  font-size: 32px;
  font-weight: 600;
  line-height: 34px;
}
.section__bottom__p {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
}

.section__bottom__link {
  margin-top: 30px;
  text-align: center;
}

.section__top {
  display: flex;
  align-items: center;
  justify-content: center;
}

.line-2 {
  font-size: 96px;
  line-height: 80px;
}
.line-3 {
  line-height: 28px;
}
</style>
